<template>
  <div>
    <div class="demo-infinite-container">
      <a-tabs defaultActiveKey="Info" tabPosition="top" @change="onTabChange">
        <a-tab-pane tab="Info Logs" key="Info">
          <pre>{{infoLogs}}</pre>
        </a-tab-pane>
        <a-tab-pane tab="Error Logs" key="Error">
          <pre>{{errorLogs}}</pre>
        </a-tab-pane>
        <a-tab-pane tab="Debug Logs" key="Debug">
          <pre>{{debugLogs}}</pre>
        </a-tab-pane>
        <a-date-picker
          slot="tabBarExtraContent"
          :defaultValue="this.$moment(this.logDate, dateFormat)"
          :format="dateFormat"
          size="small"
          @change="onDateChange"
        />
      </a-tabs>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      infoLogs: "no logs",
      errorLogs: "no logs",
      debugLogs: "no logs",
      logKey: "Info",
      logDate: moment(new Date())
        .add(-1, "days")
        .format("YYYY-MM-DD")
    };
  },
  beforeMount() {
    this.getLogs(this.logKey, this.logDate);
  },
  methods: {
    getLogs(logKey, logDate) {
      var base_API_URL = "/api/novel/getLogs";
      this.$axios
        .get(base_API_URL, { params: { key: logKey, logDate: logDate } })
        .then(res => {
          if (logKey == "Info") {
            this.infoLogs = res.data.data;
          }
          if (logKey == "Error") {
            this.errorLogs = res.data.data;
          }
          if (logKey == "Debug") {
            this.debugLogs = res.data.data;
          }
        })
        .catch(error => {
          console.warn(error);
        });
    },
    onTabChange(key) {
      this.logKey = key;
      this.getLogs(key, this.logDate);
    },
    onDateChange(date, dateString) {
      if (dateString != "") {
        this.logDate = dateString;
        this.getLogs(this.logKey, dateString);
      }
    }
  }
};
</script>
<style scoped>
pre {
  font-size:6px;
}
</style>